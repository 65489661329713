<template>
  <div class="h-100">
    <div class="overflow-scroll p-1" v-if="!uuid_selected">
      <div class="d-flex align-items-center mb-1 flex-wrap flex-xl-nowrap">
        <div class="d-flex mb-1 mb-xl-0 w-100 align-items-center">
          <b-button variant="outline-secondary" class="size-button-center" @click="$emit('slide_window')">
             <span><feather-icon icon="MenuIcon"/></span>
          </b-button>
    
          <b-input-group class="ml-1">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon"></feather-icon>
            </b-input-group-prepend>
            <b-form-input :placeholder="$t('ia.community.findPrompt')" class="input-search-history" v-model="query"></b-form-input>
            <b-input-group-append is-text class="cursor-pointer" v-if="query && query.length > 0" @click="query = undefined">
              <feather-icon icon="XIcon"></feather-icon>
            </b-input-group-append>
          </b-input-group>
        </div>
        
        <b-button 
          variant="blue-button" 
          class="blue-button create-your-own-button ml-xl-1 h-100"
          @click="redirectCreate"
        >
          {{ $t('ia.community.creteOwn') }}
        </b-button>
      </div>
      <!-- Category IMAGE history -->
      <b-row :class="`${getGrid()} grid-container m-0`" v-if="history_images.length > 0 && section_actived === 'image'">
        <div v-for="(item) in history_images" :key="item.uuid">
          <div 
            class="container-aspect-ratio-history" 
            :class="getGridHistory(item.response_server.data.length)"
            @click="openDetail(item)"
          >
            <div 
              :class="{'box-initial-history': index_inner === 0}" 
              class="box-history" 
              v-for="(img, index_inner) in item.response_server.data.slice(0, 3)" 
              :key="index_inner"
            >
              <b-img :src="img.url" class="image-history-grid" @error="errorLoad(img)" v-if="item.status === 'complete' || item.status === 'error'"></b-img>
              <b-skeleton v-else-if="item.staus === processing" class="w-100 h-100"></b-skeleton>
            </div>
          </div>
          <div class="mt-1">
            <strong>{{ formatDateIA(new Date(item.created_at), locale) }}</strong>
            <span class="d-block text-muted avenir-medium">{{ item.num_images }} {{ item.num_images === 1 ? $t('lists.image') : $t('ia.history.images') }}</span>
          </div>
        </div>
      </b-row>
      <!-- ---- -->
       <!-- Category VIDEO history -->
      <b-row :class="`grid-container-video m-0`" v-if="video_talks.length > 0 && section_actived === 'video'">
        <div v-for="(video, index) in video_talks" :key="index" class="cursor-pointer" @click="goToEditVideo(video.uuid)">
          <div class="container-aspect-ratio">
            <video :src="video.result_url" class="img-ia" v-if="video.result_url !== null"></video>
            <!-- <b-skeleton class="img-ia skeleton" v-else></b-skeleton> -->

            <div class="container-dropdown-image" @click="downloadFile(video.result_url)">
              <feather-icon icon="DownloadIcon" class="icon-download"></feather-icon>
            </div>
          </div>
          <div>
            <strong>{{ formatDateIA(new Date(video.created_at)) }}</strong>
          </div>
        </div>
      </b-row>
      <!-- ---- -->

      <!-- empty state -->
      <div v-else-if="loaded_service && origal_history_images.length === 0" class="text-center mt-5">
        <b-img :src="require('@/assets/images/svg/not-found.svg')" class="not-found-history mb-2"></b-img>
        <h3>{{ $t('ia.history.noia') }}</h3>
        <p class="avenir-medium">{{ $t('ia.history.noResults') }}</p>
      </div>
      <!--  -->
      <!-- Empty state search -->
      <div v-else-if="loaded_service" class="text-center mt-5">
        <h3>Ups!</h3>
        <div class="avenir-medium"><p>{{ $t('ia.history.noResultsMatchin') }} <strong>{{ query }}</strong></p></div>
        <b-button class="blue-button" variant="blue-button" @click="query = undefined">{{ $t('ia.history.clearSearch') }}</b-button>
      </div>
      <!--  -->
    </div>

    <container-grid 
      :key="update_container_grid"
      :history_images="history_images_detail"
      @slide_window="$emit('slide_window')"
      @back_histories="backHistories()"
      @change_uuid="changeUuidSelected"
      @edit_history="$emit('edit_history')"
      v-else
    ></container-grid>
  </div>
</template>
    
<script>

import {
  BRow,
  BImg,
  BButton,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BSkeleton,
} from 'bootstrap-vue';
import config from '@/services/config';
import { getHistoryImages, getVideoTalks } from '@/services/ia'
import { formatDateIA } from '@/libs/utils/times';
    
export default {
  name: 'historyGrid',
  components: {
    BRow,
    BImg,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BSkeleton,
    containerGrid: () => import('./containerGrid.vue')
  },
  data() {
    return {
      formatDateIA,
      index_ratio_selected: null,
      axios: null,
      cancel_search_ws: null,
      query: undefined,
      history_images: [],
      origal_history_images: [],
      loaded_service: false,
      uuid_selected: null,
      history_images_detail: [],
      update_container_grid: false,
      locale: 'es',
      video_talks: [],
      section_actived: ''
    }
  },
  async mounted() {
    this.axios = (await import('axios')).default;
  },
  created() {
    if (this.$route.params.category === 'image') {
      this.section_actived = 'image'
      this.getHistory();
    } else if (this.$route.params.category === 'video') {
      this.section_actived = 'video'
      this.getVideoTalks();
    }
  },
  methods: {
    changeUuidSelected(direction) {
      const old_index = this.history_images.findIndex(obj => obj.uuid === this.uuid_selected);
      if (direction === 'forward' && old_index < this.history_images.length - 1) this.openDetail(this.history_images[old_index + 1])
      else if (direction === 'backward' && old_index > 0) this.openDetail(this.history_images[old_index - 1])
    },
    getVideoTalks() {
      getVideoTalks().then((response) => {
        this.video_talks = response.results;
      })
    },
    goToEditVideo(video_uuid) {
      this.$router.push({ name: 'brandme_AI', params: { section: 'video' , category: 'talking_portrait', uuid: video_uuid}}).catch(() => {})
      this.$emit('edit_history', 'video')
    },
    getGrid() {
      if (this.image_active_slide && this.grid_size_active) {
        return 'grid-active-left'
      } else if (!this.image_active_slide && this.grid_size_active) {
        return 'grid-desactive-left'
      } else if (!this.grid_size_active) {
        return 'grid-long-left'
      }
    },
    getGridHistory(length) {
      let grid = ''
      switch (length) {
      case 1:
        grid = 'grid-1'
        break;
      case 2:
        grid = 'grid-2'
        break;
      case 3:
        grid = 'grid-3'
        break;
      default:
        grid = 'grid-3'
        
      }
      return grid
    },
    errorLoad(img) {
      img.url = require("@/assets/images/dashboard/imgDefault.png");
    },
    searchQuery(query) {
      const CancelToken = this.axios.CancelToken;
      if (this.cancel_search_ws) this.cancel_search_ws()
      const self = this;
      this.axios({
        method: 'post',
        url: `${config.urlCore}/api/v3/ai/request-images/`,
        headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
        data: {
          query
        },
        cancelToken: new CancelToken(function executor(c) {
          self.cancel_search_ws = c;
        })
      }).then((response) => {
        this.history_images = response.data.response.results;
      })
    },
    getHistory() {
      getHistoryImages().then((response) => {
        this.history_images = response.results;
        this.origal_history_images = this.history_images;
        this.loaded_service = true;
        this.uuid_selected = this.$route.params.uuid;
        if (this.uuid_selected) {
          this.history_images_detail = this.history_images.find((his) => his.uuid === this.uuid_selected)
        }
      })
    },
    openDetail(history) {
      this.$router.push({ name: 'brandme_AI', params: { section: 'histories', category: 'image', uuid: history.uuid}}).catch(() => {})
      this.uuid_selected = history.uuid;
      this.history_images_detail = history;
      this.update_container_grid = !this.update_container_grid;
    },
    backHistories() {
      this.uuid_selected = null;
    },
    redirectCreate() {
      this.$router.push({ name: 'brandme_AI', params: { section: this.section_actived === 'image' ? 'image' : 'video' , category: this.section_actived === 'image' ? 'txt_to_image' : 'talking_portrait'}}).catch(() => {})
      this.$emit('edit_history', this.section_actived === 'image' ? 'image' : 'video')
    },
    downloadFile(url) {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.onload = () => {
        if (xhr.status === 200) {
          const blob = xhr.response;
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          const splitted = url.split('/');
          link.download = splitted[splitted.length - 1];
          link.click();
        }
      };
      xhr.onerror = () => {
        if (xhr.status === 0)
          window.open(url, '_blank');
      };
      xhr.send();
    },
  },
  watch: {
    query(val) {
      this.searchQuery(val)
    },
    '$i18n.locale'(val) {
      this.locale = val;
    },
    '$route.params.category'(val) {
      this.section_actived = val;
      if (val === 'image') {
        this.getHistory();
      } else if (val === 'video') {
        this.getVideoTalks();
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.create-your-own-button {
  flex-shrink: 0;
  width: 100%;
  
  @media(min-width: 1200px) {
    width: auto;
  }
}

.grid-container-video {
  
  display: grid;
  grid-gap: 14px;
  grid-auto-flow: dense;
  width: 100%;
  grid-template-columns: repeat(5, 1fr);

  @media(max-width: 300px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media(max-width: 700px) {
    grid-template-columns: repeat(3, 1fr);
  }
  .img-ia {
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }
  .container-aspect-ratio {
    .container-dropdown-image {
      background-color: rgba(0, 0, 0, 0.699);
      position: absolute;
      top: 5%;
      border-radius: 0.5em;
      right: 5%;
      color: white;
      transform: translateX(50px);
      padding: 0.5em;
      transition: all 500ms;
      opacity: 0;
      .icon-download {
        width: 18px;
        height: 18px;
      }
    }
    &:hover {
      .container-dropdown-image {
        transform: translateX(0px);
        opacity: 1;
      }
    }
  }
}
.not-found-history {
  height: 100px;
  width: 100px;
}
.overflow-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  .size-button-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1rem;
    height: 3rem;
  }
  .input-search-history {
    height: 3rem;
  }
  .grid-container {
    display: grid;
    grid-gap: 14px;
    grid-auto-flow: dense;
    .grid-col-span {
      grid-column-end: span 2;
      grid-row-end: span 2;
    }
  }
  .grid-long-left {
    grid-template-columns: repeat(8, 1fr);
  }
  .grid-active-left {
    grid-template-columns: repeat(4, 1fr);
  }
  .grid-desactive-left {
    grid-template-columns: repeat(5, 1fr);
  }
  .container-aspect-ratio-history {
    display: grid;
    grid-gap: 7px;
    cursor: pointer;
    aspect-ratio: 1 / 1;
    .box-history {
      overflow: hidden;
      border: 1px solid #ccc;
    }
    .box-initial-history {
      grid-row-start: 1;
      grid-row-end: 3;

    }
    .image-history-grid {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .grid-1 {
    grid-template-columns: repeat(1, 1fr); 
  }
  .grid-2 {
    grid-template-columns: repeat(2, 1fr); 
  }
  .grid-3 {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  @media (max-width: 1000px) {
    .grid-active-left {
      grid-template-columns: repeat(3, 1fr);
    }
    .grid-desactive-left {
      grid-template-columns: repeat(4, 1fr);
    }
    .grid-long-left {
      grid-template-columns: repeat(7, 1fr);
    }
  }
  
  @media (max-width: 765px) {
    .grid-active-left, .grid-desactive-left {
      grid-template-columns: repeat(2, 1fr);
    }
    .grid-long-left {
      grid-template-columns: repeat(5, 1fr);
    }
  }
  @media (max-width: 515px) {
    .grid-active-left, .grid-desactive-left {
      grid-template-columns: repeat(1, 1fr);
    }
    .grid-long-left {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  @media (max-width: 350px) {
    .grid-long-left {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
  </style>
